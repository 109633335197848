import React from "react"
import Layout from "../components/Layout"
import styled from "@emotion/styled"
import { theme, mediaQueries } from "../components/theme"
import { Container } from "../components/Container"

const Content = styled(Container)`
  padding-top: 160px;
  padding-bottom: 160px;
  color: ${theme.colors.blue};
  background-color: ${theme.colors.white};
  text-align: center;
  h1 {
    font-weight: 700;
    font-size: ${theme.headings.h2};
  }

  p {
    font-size: ${theme.fontSizes.base};
  }

  a {
    color: ${theme.colors.blue};
    font-weight: 700;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`

export default function Home() {
  return (
    <Layout
      title="Úspěšná registrace | Educon Prague"
      description="Vaše registrace proběhla v pořádku"
    >
      <Content>
        <h1>Vaše registrace proběhla v pořádku</h1>
        <p>
          Pro více informací nás sledujte na{" "}
          <a
            href="https://www.facebook.com/eceta.cz/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Facebooku
          </a>
          .
        </p>
      </Content>
    </Layout>
  )
}
